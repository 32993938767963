<template>
  <div>
    <el-main>
      <el-button type="primary" style="margin-bottom: 10px" size="small" @click="handleAdd">+添加版本</el-button>
      <!-- 表单 -->
      <el-form class="el-form-search" label-width="140px">
        <el-form-item label="版本名称：">
          <el-input size="small" placeholder="请输入版本名称" v-model="searchForm.keywords"></el-input>
        </el-form-item>
        <el-form-item label=" " label-width="25px">
          <el-button type="primary" size="small" @click="search">搜索</el-button>
          <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="content" label="版本名称" align="center"></el-table-column>
        <el-table-column prop="version" label="版本号" align="center"></el-table-column>
        <el-table-column label="更新时间" align="center">
          <template v-slot="{ row }">{{ getDateformat(row.createtime) }}</template>
        </el-table-column>
        <el-table-column prop="merchant_mobile" label="操作" align="center">
          <template v-slot="{ row }">
            <el-button type="text" @click="handleEdit(row)" size="small">编辑</el-button>
            <el-button type="text" @click="handleVerify(row.id)" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 翻页 -->
      <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
      <el-dialog :title="(app_form.id ? '编辑' : '添加') + '版本'" :visible.sync="dialogVisible" width="800px">
        <el-form label-width="140px" ref="app_form" :model="app_form" :rules="rules">
          <el-form-item label="版本名称：" prop="content">
            <el-input v-model="app_form.content"></el-input>
          </el-form-item>
          <el-form-item label="版本号：" prop="version">
            <el-input v-model="app_form.version"></el-input>
          </el-form-item>
          <el-form-item label="模板id：" prop="template_id">
            <el-input v-model="app_form.template_id"></el-input>
          </el-form-item>
          <el-form-item label="版本描述：" prop="desc_content">
            <el-input type="textarea" :rows="10" placeholder="请输入内容" v-model="app_form.desc_content"></el-input>
          </el-form-item>
          <el-form-item label="下载地址：" prop="links">
            <el-input v-model="app_form.links"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
      </el-dialog>
    </el-main>
  </div>
</template>

<script>
import _ from 'lodash';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';

export default {
  components: {
    Paging,
  },
  data() {
    return {
      list: [],
      total_number: 0,
      searchForm: {
        page: 1,
        rows: 10,
        keywords: '',
      },
      dialogVisible: false,
      app_form: {
        version: '',
        template_id: '',
        content: '',
        desc_content: '',
        links: '',
      },
      rules: {
        version: [{ required: true, message: '请输入版本号', trigger: 'blur' }],
        template_id: [{ required: true, message: '请输入模板id', trigger: 'blur' }],
        content: [{ required: true, message: '请输入版本名称', trigger: 'blur' }],
        desc_content: [{ required: true, message: '请输入版本描述', trigger: 'blur' }],
        links: [{ required: true, message: '请输入下载地址', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        content: '',
      };
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    // 获取列表
    getList() {
      this.$axios.post(this.$api.repair.set.VersionList, this.searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleAdd() {
      this.app_form = {
        version: '',
        template_id: '',
        content: '',
        desc_content: '',
        links: '',
      };
      this.dialogVisible = true;
    },
    handleVerify(data) {
      this.$confirm('此操作将永久删除该版本记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.repair.set.VersionDelete, {
            id: data.id,
          });
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('删除成功');
            this.getList();
          }
        })
        .catch(() => {});
    },
    handleEdit(row) {
      this.app_form = {
        version: row.version,
        template_id: row.template_id,
        content: row.content,
        desc_content: row.desc_content,
        links: row.links,
        id: row.id,
      };
      this.dialogVisible = true;
    },
    confirm() {
      this.$refs.app_form.validate(valid => {
        if (valid) {
          let url = this.app_form.id ? this.$api.repair.set.VersionEdit : this.$api.repair.set.VersionAdd;

          this.$axios.post(url, this.app_form).then(res => {
            if (res.code == 0) {
              this.$message.success((this.app_form.id ? '编辑' : '添加') + '成功');
              this.dialogVisible = false;
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
